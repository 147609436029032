import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  getDocs,
  serverTimestamp,
  collection,
  query,
  where,
  orderBy,
  limit,
  updateDoc,
  onSnapshot,
  addDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
  writeBatch,
  Timestamp
} from "firebase/firestore";
import {
  getDatabase,
  ref,
  set,
  onDisconnect,
  onValue,
  serverTimestamp as rtdbServerTimestamp
} from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCNlriwUZ7HxeGJqgjYZNDGxI_NNy31enA",
  authDomain: "wise-split-be.firebaseapp.com",
  projectId: "wise-split-be",
  storageBucket: "wise-split-be.firebasestorage.app",
  messagingSenderId: "1097969179249",
  appId: "1:1097969179249:web:dadeb50dfdeffbd431d9d0",
  measurementId: "G-Y7GKF597N0",
  databaseURL: "https://wise-split-be-default-rtdb.firebaseio.com/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const realtimeDb = getDatabase(app);

// User Management Functions
// Create or update user document in Firestore
export const createOrUpdateUser = async (user) => {
  if (!user) return null;

  const userRef = doc(db, 'users', user.uid);
  const userSnap = await getDoc(userRef);

  if (!userSnap.exists()) {
    const userData = {
      userId: user.uid,
      displayName: user.displayName || '',
      email: user.email || '',
      photoURL: user.photoURL || '',
      createdAt: serverTimestamp(),
      lastActive: serverTimestamp(),
      isOnline: true,
      lastSeen: serverTimestamp(),
      settings: {
        defaultCurrency: 'USD',
        notifications: true
      },
      recentInteractions: []
    };

    try {
      await setDoc(userRef, userData);
      return userData;
    } catch (error) {
      console.error("Error creating user document:", error);
      throw error;
    }
  } else {
    const existingData = userSnap.data();
    const userData = {
      ...existingData,
      userId: user.uid,
      displayName: user.displayName || existingData.displayName || '',
      email: user.email || existingData.email || '',
      photoURL: user.photoURL || existingData.photoURL || '',
      lastActive: serverTimestamp(),
      isOnline: true,
      lastSeen: serverTimestamp(),
      settings: {
        ...existingData.settings || {},
        defaultCurrency: existingData.settings?.defaultCurrency || 'USD',
        notifications: existingData.settings?.notifications ?? true
      },
      recentInteractions: existingData.recentInteractions || []
    };
    
    try {
      await setDoc(userRef, userData);
      return userData;
    } catch (error) {
      console.error("Error updating user document:", error);
      throw error;
    }
  }
};

// User Search Functions
export const searchUsers = async (searchTerm, excludeUserIds = []) => {
  if (!searchTerm || searchTerm.length < 2) return [];
  
  const usersRef = collection(db, 'users');
  
  try {
    const nameQuery = query(
      usersRef,
      where('displayName', '>=', searchTerm.toLowerCase()),
      where('displayName', '<=', searchTerm.toLowerCase() + '\uf8ff'),
      orderBy('displayName'),
      limit(5)
    );

    const emailQuery = query(
      usersRef,
      where('email', '>=', searchTerm.toLowerCase()),
      where('email', '<=', searchTerm.toLowerCase() + '\uf8ff'),
      orderBy('email'),
      limit(5)
    );

    const [nameSnapshot, emailSnapshot] = await Promise.all([
      getDocs(nameQuery),
      getDocs(emailQuery)
    ]);

    const results = new Map();
    
    nameSnapshot.forEach((doc) => {
      const userData = doc.data();
      if (!excludeUserIds.includes(userData.userId)) {
        results.set(userData.userId, userData);
      }
    });

    emailSnapshot.forEach((doc) => {
      const userData = doc.data();
      if (!excludeUserIds.includes(userData.userId)) {
        results.set(userData.userId, userData);
      }
    });

    return Array.from(results.values());
  } catch (error) {
    console.error('Error searching users:', error);
    throw new Error('Unable to search users. Please try again later.');
  }
};

// Initialize user presence system
export const initializePresence = async (userId) => {
  if (!userId) return;

  const userStatusRef = ref(realtimeDb, `/status/${userId}`);
  const userStatusFirestoreRef = doc(db, 'users', userId);

  const connectionRef = ref(realtimeDb, '.info/connected');

  onValue(connectionRef, async (snapshot) => {
    if (snapshot.val() === false) {
      await updateDoc(userStatusFirestoreRef, {
        isOnline: false,
        lastSeen: serverTimestamp()
      });
      return;
    }

    try {
      await onDisconnect(userStatusRef).set({
        isOnline: false,
        lastSeen: rtdbServerTimestamp()
      });

      await Promise.all([
        set(userStatusRef, {
          isOnline: true,
          lastSeen: rtdbServerTimestamp()
        }),
        updateDoc(userStatusFirestoreRef, {
          isOnline: true,
          lastSeen: serverTimestamp()
        })
      ]);
    } catch (error) {
      console.error("Error updating presence:", error);
    }
  });
};

// Get user's online status
export const getUserOnlineStatus = async (userId) => {
  if (!userId) return { isOnline: false, lastSeen: null };
  
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) return { isOnline: false, lastSeen: null };
    
    const userData = userDoc.data();
    return {
      isOnline: userData.isOnline || false,
      lastSeen: userData.lastSeen?.toDate() || null
    };
  } catch (error) {
    console.error('Error getting user online status:', error);
    return { isOnline: false, lastSeen: null };
  }
};

// Subscribe to user's online status changes
export const subscribeToUserPresence = (userId, callback) => {
  if (!userId) return () => {};
  
  const userRef = doc(db, 'users', userId);
  return onSnapshot(userRef, (doc) => {
    if (!doc.exists()) {
      callback({ isOnline: false, lastSeen: null });
      return;
    }

    const userData = doc.data();
    callback({
      isOnline: userData.isOnline || false,
      lastSeen: userData.lastSeen?.toDate() || null
    });
  }, (error) => {
    console.error('Error subscribing to presence:', error);
    callback({ isOnline: false, lastSeen: null });
  });
};

// Recent Interactions Functions
export const updateRecentInteraction = async (currentUserId, interactedUserId) => {
  if (!currentUserId || !interactedUserId || currentUserId === interactedUserId) return;

  try {
    const userRef = doc(db, 'users', currentUserId);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();
      let recentInteractions = userData.recentInteractions || [];

      recentInteractions = recentInteractions.filter(id => id !== interactedUserId);

      recentInteractions.unshift(interactedUserId);

      recentInteractions = recentInteractions.slice(0, 10);

      await updateDoc(userRef, {
        recentInteractions,
        lastActive: serverTimestamp()
      });
    }
  } catch (error) {
    console.error('Error updating recent interactions:', error);
  }
};

export const getRecentlyInteractedUsers = async (userId) => {
  if (!userId) return [];

  try {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) return [];

    const userData = userSnap.data();
    const recentInteractions = userData.recentInteractions || [];

    const recentUsers = await Promise.all(
      recentInteractions.slice(0, 5).map(async (interactedUserId) => {
        const interactedUserRef = doc(db, 'users', interactedUserId);
        const interactedUserSnap = await getDoc(interactedUserRef);
        
        if (interactedUserSnap.exists()) {
          return interactedUserSnap.data();
        }
        return null;
      })
    );

    return recentUsers.filter(Boolean);
  } catch (error) {
    console.error('Error getting recent interactions:', error);
    return [];
  }
};

// Group Management Functions
export const createGroup = async (data) => {
  try {
    const { name, createdBy, members = [] } = data;
    
    if (!name || !createdBy) {
      throw new Error('Name and creator are required');
    }

    const currentTime = Timestamp.now();
    
    const creatorDoc = await getDoc(doc(db, 'users', createdBy));
    if (!creatorDoc.exists()) {
      throw new Error('Creator user not found');
    }
    const creatorData = creatorDoc.data();

    const formattedMembers = [{
      userId: createdBy,
      addedBy: createdBy,
      addedAt: currentTime,
      displayName: creatorData.displayName || '',
      email: creatorData.email || '',
      photoURL: creatorData.photoURL || ''
    }];

    const memberIds = [createdBy];

    for (const member of members) {
      if (member.userId !== createdBy) {
        formattedMembers.push({
          userId: member.userId,
          addedBy: createdBy,
          addedAt: currentTime,
          displayName: member.displayName || '',
          email: member.email || '',
          photoURL: member.photoURL || ''
        });
        memberIds.push(member.userId);
      }
    }

    const newGroupData = {
      name: name.trim(),
      createdBy,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      members: formattedMembers,
      memberIds,
      settings: {
        currency: 'USD',
        defaultSplitType: 'equal'
      }
    };

    const groupRef = await addDoc(collection(db, 'groups'), newGroupData);
    
    return groupRef.id;

  } catch (error) {
    console.error('Error creating group:', error);
    throw new Error('Failed to create group. Please try again.');
  }
};

export const getGroupById = async (groupId) => {
  try {
    const groupRef = doc(db, 'groups', groupId);
    const groupSnap = await getDoc(groupRef);
    
    if (!groupSnap.exists()) {
      throw new Error('Group not found');
    }

    return {
      id: groupSnap.id,
      ...groupSnap.data()
    };
  } catch (error) {
    console.error('Error getting group:', error);
    throw error;
  }
};

export const getUserGroups = async (userId) => {
  try {
    if (!userId) {
      throw new Error('User ID is required');
    }

    const groupsQuery = query(
      collection(db, 'groups'),
      where('memberIds', 'array-contains', userId),
      orderBy('createdAt', 'desc')
    );

    const groupsSnap = await getDocs(groupsQuery);

    return groupsSnap.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error getting user groups:', error);
    throw error;
  }
};

export const addMemberToGroup = async (groupId, newMember, addedBy) => {
  try {
    const groupRef = doc(db, 'groups', groupId);
    
    await updateDoc(groupRef, {
      members: arrayUnion({
        userId: newMember.userId,
        addedBy: addedBy,
        addedAt: Timestamp.now(),
        displayName: newMember.displayName || '',
        email: newMember.email || '',
        photoURL: newMember.photoURL || ''
      }),
      memberIds: arrayUnion(newMember.userId),
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error adding member to group:', error);
    throw error;
  }
};

export const removeMemberFromGroup = async (groupId, memberId) => {
  try {
    const groupRef = doc(db, 'groups', groupId);
    const groupSnap = await getDoc(groupRef);
    
    if (!groupSnap.exists()) {
      throw new Error('Group not found');
    }

    const groupData = groupSnap.data();
    const memberToRemove = groupData.members.find(m => m.userId === memberId);

    if (!memberToRemove) {
      throw new Error('Member not found in group');
    }

    await updateDoc(groupRef, {
      members: arrayRemove(memberToRemove),
      memberIds: arrayRemove(memberId),
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error removing member from group:', error);
    throw error;
  }
};

export const deleteGroup = async (groupId) => {
  try {
    const groupRef = doc(db, 'groups', groupId);
    await deleteDoc(groupRef);
  } catch (error) {
    console.error('Error deleting group:', error);
    throw error;
  }
};

export const subscribeToGroup = (groupId, callback) => {
  const groupRef = doc(db, 'groups', groupId);
  
  return onSnapshot(groupRef, (doc) => {
    if (doc.exists()) {
      callback({
        id: doc.id,
        ...doc.data()
      });
    } else {
      callback(null);
    }
  }, (error) => {
    console.error('Error subscribing to group:', error);
    callback(null);
  });
};

export const subscribeToUserGroups = (userId, callback) => {
  const groupsQuery = query(
    collection(db, 'groups'),
    where('members', 'array-contains', { userId }),
    orderBy('updatedAt', 'desc'),
    limit(10)
  );

  return onSnapshot(groupsQuery, (snapshot) => {
    const groups = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    callback(groups);
  }, (error) => {
    console.error('Error subscribing to user groups:', error);
    callback([]);
  });
};