import React from 'react';
import { Box, styled } from '@mui/material';
import HeaderNav from '../components/HeaderNav';

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 3, 3, 3), // top, right, bottom, left
  width: '100%',
  minHeight: '100vh',
  position: 'relative',
}));

const ContentWrapper = styled(Box)(() => ({
  width: '100%',
  height: '100%',
}));

const PageLayout = ({ children }) => {
  return (
    <PageContainer>
      <HeaderNav />
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </PageContainer>
  );
};

export default PageLayout;