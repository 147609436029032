import React from "react";
import { Box, Typography } from "@mui/material";

const DashboardPage = () => {
  return (
    <Box>
      <Box
        sx={{
          p: 4,
          borderRadius: 2,
          bgcolor: "background.paper",
          backgroundImage:
            "linear-gradient(145deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{ color: "primary.main", fontWeight: "bold" }}
          >
            Dashboard
          </Typography>
          <Box
            sx={{
              px: 2,
              py: 0.5,
              borderRadius: "pill",
              bgcolor: "primary.main",
              color: "background.paper",
              fontSize: "0.875rem",
              fontWeight: "medium",
            }}
          >
            Coming Soon
          </Box>
        </Box>
        <Typography variant="body1" color="text.secondary">
          Future home of your expense analytics and insights.
        </Typography>
      </Box>
    </Box>
  );
};

export default DashboardPage;
