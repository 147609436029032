import React, { useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  useTheme,
  useMediaQuery,
  IconButton,
  alpha,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  Receipt as ReceiptIcon,
  Settings as SettingsIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import WiseSplitLogo from "../components/svg/WiseSplitLogo";
import PageLayout from "./PageLayout";

// Constants
const DRAWER_WIDTH = 68;

// Styled components
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    color: theme.palette.text.primary,
    backdropFilter: "blur(8px)",
    fontSize: 13,
    fontWeight: 500,
    padding: "8px 12px",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    width: DRAWER_WIDTH,
    backgroundImage:
      "linear-gradient(145deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%)",
    backdropFilter: "blur(10px)",
    borderRight: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    overflow: "hidden",
  },
}));

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => ({
  margin: "4px 8px",
  padding: "12px",
  borderRadius: theme.shape.borderRadius,
  minWidth: 0,
  justifyContent: "center",
  position: "relative",
  "&:before": active
    ? {
        content: '""',
        position: "absolute",
        left: -8,
        top: "50%",
        transform: "translateY(-50%)",
        width: 3,
        height: "50%",
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius,
      }
    : {},
  ...(active && {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
  }),
  "&:hover": {
    backgroundColor: active
      ? alpha(theme.palette.primary.main, 0.2)
      : alpha(theme.palette.background.paper, 0.1),
  },
}));

const Logo = styled(Box)(({ theme }) => ({
  height: 64,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  minHeight: "100vh",
  background: `linear-gradient(135deg, ${alpha("#181A1F", 0.95)} 0%, ${alpha(
    "#181A1F",
    0.98
  )} 100%)`,
}));

const navigationItems = [
  {
    id: "dashboard",
    label: "Dashboard",
    icon: DashboardIcon,
    path: "/dashboard",
  },
  {
    id: "bills",
    label: "Bills",
    icon: ReceiptIcon,
    path: "/bills",
  },
  {
    id: "settings",
    label: "Settings",
    icon: SettingsIcon,
    path: "/settings",
  },
];

const SidebarLayout = ({ children }) => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  // Extract the current route from the pathname
  const currentRoute = location.pathname.split("/")[1] || "dashboard";

  // Check if a navigation item is active
  const isItemActive = (itemId) => currentRoute === itemId;

  const drawer = (
    <Box>
      <Logo>
        <WiseSplitLogo size={40} />
      </Logo>

      <List>
        {navigationItems.map((item) => {
          const isActive = isItemActive(item.id);
          const Icon = item.icon;

          return (
            <ListItem key={item.id} disablePadding>
              <StyledTooltip title={item.label} placement="right">
                <StyledListItemButton
                  active={isActive}
                  onClick={() => {
                    navigate(item.path);
                    if (isMobile) setMobileOpen(false);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                      color: isActive ? "primary.main" : "inherit",
                      transition: theme.transitions.create("color"),
                    }}
                  >
                    <Icon
                      sx={{
                        transform: isActive ? "scale(1.1)" : "scale(1)",
                        transition: theme.transitions.create("transform"),
                      }}
                    />
                  </ListItemIcon>
                </StyledListItemButton>
              </StyledTooltip>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  if (isMobile) {
    return (
      <Box sx={{ display: "flex" }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          sx={{
            "& .MuiDrawer-paper": {
              width: DRAWER_WIDTH,
              backgroundColor: "background.paper",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            minHeight: "100vh",
            background: theme.palette.background.default,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setMobileOpen(true)}
            sx={{
              position: "fixed",
              left: 16,
              top: 16,
              zIndex: 1200,
              bgcolor: "background.paper",
              "&:hover": { bgcolor: "background.default" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <PageLayout>{children}</PageLayout>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      <StyledDrawer variant="permanent">{drawer}</StyledDrawer>
      <MainContent>
        <PageLayout>{children}</PageLayout>
      </MainContent>
    </Box>
  );
};

export default SidebarLayout;
