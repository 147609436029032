import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, googleProvider } from "../backend-services/firebase";
import { signInWithPopup } from "firebase/auth";
import { useAuth } from "../contexts/AuthContext";
import {
  Box,
  Button,
  Container,
  Typography,
  Paper,
  useMediaQuery,
  styled,
  alpha,
} from "@mui/material";

const GradientTypography = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  marginBottom: theme.spacing(2),
}));

const LogoSVG = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Background Circle */}
    <circle cx="16" cy="16" r="16" fill="#2A2D36" />

    {/* Main Split Line */}
    <path
      d="M16 4 L16 28"
      stroke="#F5DB54"
      strokeWidth="2"
      strokeLinecap="round"
    />

    {/* Left Side Splits */}
    <path
      d="M16 12 L8 12"
      stroke="#F5DB54"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M16 20 L8 20"
      stroke="#F5DB54"
      strokeWidth="2"
      strokeLinecap="round"
    />

    {/* Right Side Splits */}
    <path
      d="M16 12 L24 12"
      stroke="#9968FF"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M16 20 L24 20"
      stroke="#9968FF"
      strokeWidth="2"
      strokeLinecap="round"
    />

    {/* Decorative Dots */}
    <circle cx="8" cy="12" r="2" fill="#F5DB54" />
    <circle cx="8" cy="20" r="2" fill="#F5DB54" />
    <circle cx="24" cy="12" r="2" fill="#9968FF" />
    <circle cx="24" cy="20" r="2" fill="#9968FF" />
  </svg>
);

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "600px",
  width: "100%",
  display: "flex",
  flexDirection: useMediaQuery(theme.breakpoints.up("md")) ? "row" : "column",
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  overflow: "hidden",
}));

const BrandSection = styled(Box)(({ theme }) => ({
  width: "100%",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  padding: theme.spacing(4),
  background: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.main,
    0.1
  )}, ${alpha(theme.palette.secondary.main, 0.1)})`,
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient(circle at 50% 50%, ${alpha(
      theme.palette.primary.main,
      0.1
    )}, transparent 70%)`,
  },
}));

const LoginSection = styled(Box)(({ theme }) => ({
  width: "100%",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(4),
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
}));

const GoogleButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "#000",
  padding: "12px 24px",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
  "& .MuiButton-startIcon": {
    marginRight: theme.spacing(2),
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "relative",
  zIndex: 1,
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const LoginScreen = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setToken } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  const handleGoogleSignIn = async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const idToken = await user.getIdToken();
      localStorage.setItem("firebaseToken", idToken);
      setToken?.(idToken);
      let tokenRefreshTimeout;
      const refreshToken = async () => {
        try {
          const newToken = await user.getIdToken(true);
          localStorage.setItem("firebaseToken", newToken);
          setToken?.(newToken);
          tokenRefreshTimeout = setTimeout(refreshToken, 45 * 60 * 1000);
        } catch (error) {
          console.error("Error refreshing token:", error);
        }
      };
      tokenRefreshTimeout = setTimeout(refreshToken, 45 * 60 * 1000);
      const from = location.state?.from?.pathname || "/dashboard";
      navigate(from);
    } catch (error) {
      let errorMessage;
      switch (error.code) {
        case "auth/popup-closed-by-user":
          errorMessage =
            "Sign-in popup was closed before completing the process";
          break;
        case "auth/cancelled-popup-request":
          errorMessage =
            "Multiple popup requests were triggered. Only one popup can be open at a time";
          break;
        case "auth/popup-blocked":
          errorMessage = "The sign-in popup was blocked by the browser";
          break;
        default:
          errorMessage = error.message;
      }

      setError(errorMessage);
      console.error("Error during sign in:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: `linear-gradient(135deg, ${alpha(
          "#181A1F",
          0.95
        )} 0%, ${alpha("#181A1F", 0.98)} 100%)`,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          py: { xs: 2, md: 4 },
        }}
      >
        <StyledPaper>
          <BrandSection>
            <LogoContainer>
              <LogoSVG />
              <GradientTypography
                variant="h3"
                sx={{
                  fontSize: { xs: "2.5rem", md: "3.5rem" },
                  fontWeight: 700,
                  letterSpacing: "-0.02em",
                  mt: 3,
                }}
              >
                Wisesplit
              </GradientTypography>
              <Typography
                variant="h6"
                color="text.secondary"
                sx={{ mt: 2, textAlign: "center" }}
              >
                Split bills effortlessly with friends and groups
              </Typography>
            </LogoContainer>
          </BrandSection>

          <LoginSection>
            <Box sx={{ width: "100%", maxWidth: "400px" }}>
              <Typography variant="h5" align="center" gutterBottom>
                Welcome Back
              </Typography>
              <Typography
                variant="body1"
                align="center"
                color="text.secondary"
                sx={{ mb: 4 }}
              >
                Sign in to continue to WiseSplit
              </Typography>

              {error && (
                <Typography color="error" align="center" sx={{ mb: 2 }}>
                  {error}
                </Typography>
              )}

              <GoogleButton
                fullWidth
                variant="contained"
                onClick={handleGoogleSignIn}
                startIcon={
                  <svg width="20" height="20" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    />
                    <path
                      fill="currentColor"
                      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    />
                    <path
                      fill="currentColor"
                      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    />
                    <path
                      fill="currentColor"
                      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    />
                  </svg>
                }
              >
                {loading ? "Signing in..." : "Continue with Google"}
              </GoogleButton>

              <Typography
                variant="caption"
                align="center"
                color="text.secondary"
                sx={{
                  display: "block",
                  mt: 3,
                  opacity: 0.7,
                }}
              >
                By continuing, you agree to our Terms of Service and Privacy
                Policy
              </Typography>
            </Box>
          </LoginSection>
        </StyledPaper>
      </Container>
    </Box>
  );
};

export default LoginScreen;
