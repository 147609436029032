import React from 'react';

const WiseSplitLogo = ({ size = 32 }) => (
  <svg viewBox="0 0 32 32" width={size} height={size} xmlns="http://www.w3.org/2000/svg">
    {/* Background Circle */}
    <circle cx="16" cy="16" r="16" fill="#222429"/>
    
    {/* Main Split Line */}
    <path d="M16 4 L16 28" stroke="#F5DB54" strokeWidth="2" strokeLinecap="round"/>
    
    {/* Left Side Splits */}
    <path d="M16 12 L8 12" stroke="#F5DB54" strokeWidth="2" strokeLinecap="round"/>
    <path d="M16 20 L8 20" stroke="#F5DB54" strokeWidth="2" strokeLinecap="round"/>
    
    {/* Right Side Splits */}
    <path d="M16 12 L24 12" stroke="#9968FF" strokeWidth="2" strokeLinecap="round"/>
    <path d="M16 20 L24 20" stroke="#9968FF" strokeWidth="2" strokeLinecap="round"/>
    
    {/* Decorative Dots */}
    <circle cx="8" cy="12" r="2" fill="#F5DB54"/>
    <circle cx="8" cy="20" r="2" fill="#F5DB54"/>
    <circle cx="24" cy="12" r="2" fill="#9968FF"/>
    <circle cx="24" cy="20" r="2" fill="#9968FF"/>
  </svg>
);

export default WiseSplitLogo;