import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  alpha,
  styled,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../backend-services/firebase';

const StyledHeader = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  zIndex: 1100,
}));

const StyledAvatar = styled(IconButton)(({ theme }) => ({
  padding: 0,
  width: 40,
  height: 40,
  borderRadius: '50%',
  border: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    borderColor: alpha(theme.palette.primary.main, 0.4),
  },
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    backdropFilter: 'blur(10px)',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    marginTop: theme.spacing(1),
    minWidth: 180,
  },
  '& .MuiMenuItem-root': {
    fontSize: '0.875rem',
    padding: theme.spacing(1, 2),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
}));

const HeaderNav = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const theme = useTheme();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/login');
      handleMenuClose();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <StyledHeader>
      <StyledAvatar 
        onClick={handleMenuOpen}
        sx={{
          '& .MuiAvatar-root': {
            width: '100%',
            height: '100%',
          }
        }}
      >
        <Avatar 
          src={user?.photoURL || '/api/placeholder/40/40'} 
          alt={user?.displayName || 'User'}
        />
      </StyledAvatar>
      
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem 
          onClick={handleSignOut}
          sx={{ 
            color: theme.palette.error.main,
            '&:hover': {
              backgroundColor: alpha(theme.palette.error.main, 0.1),
            }
          }}
        >
          Sign Out
        </MenuItem>
      </StyledMenu>
    </StyledHeader>
  );
};

export default HeaderNav;