import React, { useState, useEffect } from "react";
import confetti from "canvas-confetti";
import HeaderNav from "./HeaderNav";
import {
  Alert,
  Avatar,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Chip,
  Container,
  Grid,
  Paper,
  ThemeProvider,
  createTheme,
  styled,
  alpha,
  CssBaseline,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  useMediaQuery,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  AttachMoney as MoneyIcon,
  NavigateNext as NextIcon,
  NavigateBefore as BackIcon,
  ArrowRightAlt as RightIcon,
  Groups as GroupsIcon,
  Receipt as ReceiptIcon,
  PersonAdd as PersonAddIcon,
} from "@mui/icons-material";
import {
  createGroup,
  getUserGroups,
  updateGroup,
  addMemberToGroup,
  removeMemberFromGroup,
  deleteGroup,
  getGroupById,
  updateGroupSettings,
} from "../backend-services/firebase";
import { useAuth } from "../contexts/AuthContext";
import UserSearch from "./UserSearch";

// Create custom theme
const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#181A1F",
      paper: "#222429",
    },
    primary: {
      main: "#F5DB54",
    },
    secondary: {
      main: "#9968FF",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "rgba(255, 255, 255, 0.7)",
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
      letterSpacing: "-0.02em",
    },
    h6: {
      fontWeight: 600,
      letterSpacing: "-0.01em",
    },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundImage:
            "linear-gradient(145deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%)",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(255, 255, 255, 0.05)",
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          textTransform: "none",
          fontWeight: 600,
          padding: "10px 20px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
});

const StyledCard = styled(Card)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
  },
}));

const GradientTypography = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  marginBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  background: alpha(theme.palette.secondary.main, 0.1),
  border: `1px solid ${alpha(theme.palette.secondary.main, 0.3)}`,
  "&:hover": {
    background: alpha(theme.palette.secondary.main, 0.2),
  },
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: `linear-gradient(120deg, ${alpha(
      theme.palette.primary.main,
      0
    )}, ${alpha(theme.palette.primary.main, 0.3)}, ${alpha(
      theme.palette.primary.main,
      0
    )})`,
    transform: "translateX(-100%)",
  },
  "&:hover::after": {
    transform: "translateX(100%)",
    transition: "transform 0.6s ease-in-out",
  },
}));

const StepContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  minHeight: 400,
  display: "flex",
  flexDirection: "column",
}));

const ModeSelectorCard = styled(StyledCard)(({ theme }) => ({
  cursor: "pointer",
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: `0 12px 40px ${alpha(theme.palette.primary.main, 0.15)}`,
  },
}));

const GroupCard = styled(Card)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: "blur(10px)",
  borderRadius: theme.shape.borderRadius * 2,
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: `0 8px 24px ${alpha(theme.palette.common.black, 0.15)}`,
  },
}));

const MemberChip = styled(Chip)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  border: `1px solid ${alpha(theme.palette.secondary.main, 0.2)}`,
  "& .MuiChip-label": {
    color: theme.palette.text.primary,
  },
  "& .MuiChip-deleteIcon": {
    color: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.error.dark,
    },
  },
}));

const StepperContent = ({ activeStep, steps }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return !isMobile ? (
    <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  ) : (
    <MobileStepper
      variant="dots"
      steps={steps.length}
      position="static"
      activeStep={activeStep}
      sx={{
        backgroundColor: "transparent",
        mb: 4,
        "& .MuiMobileStepper-dot": {
          backgroundColor: alpha(theme.palette.primary.main, 0.3),
        },
        "& .MuiMobileStepper-dotActive": {
          backgroundColor: theme.palette.primary.main,
        },
      }}
      nextButton={null}
      backButton={null}
    />
  );
};

const BillSplitter = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mode, setMode] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [bills, setBills] = useState([]);
  const [currentBill, setCurrentBill] = useState({
    description: "",
    amount: "",
    paidBy: "",
    splitType: "equal",
    participants: [],
  });
  const [participants, setParticipants] = useState([]);
  const [newParticipant, setNewParticipant] = useState("");
  const [settlements, setSettlements] = useState([]);
  const [participantShares, setParticipantShares] = useState({});
  const [currentGroup, setCurrentGroup] = useState({
    id: null,
    name: "",
    participants: [],
  });
  const [showGroupForm, setShowGroupForm] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const fetchGroups = async () => {
      if (!user?.uid || isCreatingGroup) {
        return;
      }
      
      try {
        setLoadingGroups(true);
        setFetchError(null);
        const userGroups = await getUserGroups(user.uid);
        setGroups(userGroups);
      } catch (error) {
        console.error("Error fetching groups:", error);
        setFetchError(error.message);
        alert("Failed to load groups. Please try again.");
      } finally {
        setLoadingGroups(false);
      }
    };
  
    fetchGroups();
  }, [user, isCreatingGroup]);

  const handleAddGroup = async () => {
    if (!currentGroup.name.trim() || !user?.uid) {
      alert("Please enter a group name");
      return;
    }
  
    try {
      setIsCreatingGroup(true);
  
      const groupId = await createGroup({
        name: currentGroup.name.trim(),
        createdBy: user.uid,
        members: currentGroup.participants
      });
  
      const newGroup = await getGroupById(groupId);
      
      setGroups(prevGroups => [...prevGroups, newGroup]);
      
      setCurrentGroup({
        id: null,
        name: "",
        participants: []
      });
      setShowGroupForm(false);
  
    } catch (error) {
      console.error("Error creating group:", error);
      alert(error.message || "Failed to create group. Please try again.");
    } finally {
      setIsCreatingGroup(false);
    }
  };

  const handleDeleteGroup = async (groupId) => {
    if (!window.confirm("Are you sure you want to delete this group?")) {
      return;
    }
  
    try {
      await deleteGroup(groupId);
      setGroups(prevGroups => prevGroups.filter(group => group.id !== groupId));
      if (selectedGroup === groupId) {
        setSelectedGroup(null);
      }
    } catch (error) {
      console.error("Error deleting group:", error);
      alert("Failed to delete group. Please try again.");
    }
  };

  const handleAddParticipantToGroup = async (groupId, participant) => {
    try {
      await addMemberToGroup(groupId, participant, user.uid);

      setGroups((prevGroups) =>
        prevGroups.map((group) => {
          if (group.id === groupId) {
            return {
              ...group,
              members: [
                ...group.members,
                {
                  userId: participant.userId,
                  addedBy: user.uid,
                  addedAt: new Date(),
                },
              ],
            };
          }
          return group;
        })
      );
    } catch (error) {
      console.error("Error adding participant to group:", error);
      alert("Failed to add participant. Please try again.");
    }
  };

  const handleRemoveParticipantFromGroup = async (groupId, participantId) => {
    const participantInBills = bills.some(
      (bill) =>
        bill.groupId === groupId &&
        (bill.paidBy === participantId || bill.shares[participantId])
    );

    if (participantInBills) {
      alert("Cannot remove participant who is involved in bills");
      return;
    }

    try {
      await removeMemberFromGroup(groupId, participantId);

      setGroups((prevGroups) =>
        prevGroups.map((group) => {
          if (group.id === groupId) {
            return {
              ...group,
              members: group.members.filter(
                (member) => member.userId !== participantId
              ),
            };
          }
          return group;
        })
      );
    } catch (error) {
      console.error("Error removing participant from group:", error);
      alert("Failed to remove participant. Please try again.");
    }
  };

  const updateParticipantShare = (participantId, type, value) => {
    setParticipantShares((prev) => ({
      ...prev,
      [participantId]: {
        ...prev[participantId],
        [type]: parseFloat(value) || 0,
      },
    }));
  };

  const addBill = () => {
    if (!selectedGroup) {
      alert("Please select a group first");
      return;
    }

    if (currentBill.description && currentBill.amount && currentBill.paidBy) {
      const group = groups.find((g) => g.id === selectedGroup);
      const selectedParticipants = group.participants.map((p) => ({
        userId: p.userId,
        shares: participantShares[p.userId]?.shares || 1,
        percentage:
          participantShares[p.userId]?.percentage ||
          100 / group.participants.length,
      }));

      const billToAdd = {
        ...currentBill,
        groupId: selectedGroup,
        groupName: group.name,
        participants: selectedParticipants,
        amount: parseFloat(currentBill.amount),
      };

      const processedBill = processParticipantShares(billToAdd);
      setBills([...bills, processedBill]);

      setCurrentBill({
        description: "",
        amount: "",
        paidBy: "",
        splitType: "equal",
        participants: [],
      });
    }
  };

  const processParticipantShares = (bill) => {
    const amount = parseFloat(bill.amount);
    const shares = {};

    let participants;
    if (mode === "group") {
      participants =
        groups.find((g) => g.id === selectedGroup)?.participants || [];
    } else {
      participants = bill.participants;
    }

    switch (bill.splitType) {
      case "equal":
        const perPerson = amount / participants.length;
        participants.forEach((p) => {
          shares[p.userId] = Math.round(perPerson * 100) / 100;
        });
        break;

      case "shares":
        const totalShares = participants.reduce(
          (sum, p) => sum + (participantShares[p.userId]?.shares || 1),
          0
        );
        participants.forEach((p) => {
          const share =
            (amount * (participantShares[p.userId]?.shares || 1)) / totalShares;
          shares[p.userId] = Math.round(share * 100) / 100;
        });
        break;

      case "percentage":
        const totalPercentage = participants.reduce(
          (sum, p) => sum + (participantShares[p.userId]?.percentage || 0),
          0
        );
        if (Math.abs(totalPercentage - 100) > 0.01) {
          alert("Total percentage must equal 100%");
          return null;
        }
        participants.forEach((p) => {
          const share =
            (amount * (participantShares[p.userId]?.percentage || 0)) / 100;
          shares[p.userId] = Math.round(share * 100) / 100;
        });
        break;
      default:
        break;
    }

    // Verify that shares sum up to the total amount (within rounding error)
    const totalShares = Object.values(shares).reduce(
      (sum, share) => sum + share,
      0
    );
    if (Math.abs(totalShares - amount) > 0.02) {
      // Adjust the largest share to account for rounding differences
      const maxShare = Math.max(...Object.values(shares));
      const personWithMaxShare = Object.entries(shares).find(
        ([, share]) => share === maxShare
      )[0];
      shares[personWithMaxShare] += amount - totalShares;
    }

    return { ...bill, shares };
  };

  const calculateSettlements = () => {
    if (!selectedGroup && mode === "group") return;

    const balances = {};
    let relevantParticipants;
    let relevantBills;

    if (mode === "group") {
      relevantParticipants =
        groups.find((g) => g.id === selectedGroup)?.participants || [];
      relevantBills = bills.filter((bill) => bill.groupId === selectedGroup);
    } else {
      relevantParticipants = participants;
      relevantBills = bills;
    }

    // Initialize balances
    relevantParticipants.forEach((p) => (balances[p.userId] = 0));

    // Calculate net balances
    relevantBills.forEach((bill) => {
      balances[bill.paidBy] =
        (balances[bill.paidBy] || 0) + parseFloat(bill.amount);

      Object.entries(bill.shares || {}).forEach(([userId, share]) => {
        balances[userId] = (balances[userId] || 0) - parseFloat(share);
      });
    });

    // Round balances
    Object.keys(balances).forEach((userId) => {
      balances[userId] = Math.round(balances[userId] * 100) / 100;
    });

    const newSettlements = optimizeSettlements(balances);
    setSettlements(newSettlements);
    return newSettlements;
  };

  const optimizeSettlements = (balances) => {
    const settlements = [];
    const roundedBalances = { ...balances };

    // Helper function to check if balances are settled (all close to zero)
    const isSettled = (balances) =>
      Object.values(balances).every((balance) => Math.abs(balance) < 0.01);

    while (!isSettled(roundedBalances)) {
      // Find maximum creditor and debtor
      const maxCreditor = Object.entries(roundedBalances).reduce(
        (max, [person, balance]) =>
          balance > (max?.balance || 0) ? { person, balance } : max,
        null
      );

      const maxDebtor = Object.entries(roundedBalances).reduce(
        (min, [person, balance]) =>
          balance < (min?.balance || 0) ? { person, balance } : min,
        null
      );

      if (
        !maxCreditor ||
        !maxDebtor ||
        Math.abs(maxCreditor.balance) < 0.01 ||
        Math.abs(maxDebtor.balance) < 0.01
      ) {
        break;
      }

      // Calculate the amount to settle
      const amount = Math.min(maxCreditor.balance, -maxDebtor.balance);

      if (amount > 0) {
        // Round to 2 decimal places
        const roundedAmount = Math.round(amount * 100) / 100;

        settlements.push({
          from: maxDebtor.person,
          to: maxCreditor.person,
          amount: roundedAmount,
        });

        // Update balances
        roundedBalances[maxCreditor.person] -= roundedAmount;
        roundedBalances[maxDebtor.person] += roundedAmount;
      } else {
        break;
      }
    }

    return settlements;
  };

  const showConfetti = () => {
    if (!shouldShowConfetti) return; // Only show confetti if flag is true

    const duration = 3 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    const randomInRange = (min, max) => Math.random() * (max - min) + min;

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        clearInterval(interval);
        setShouldShowConfetti(false); // Reset the flag after animation
        return;
      }

      const particleCount = 50 * (timeLeft / duration);

      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      });
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      });
    }, 250);
  };

  const handleCalculateSettlements = () => {
    calculateSettlements();
    setShouldShowConfetti(true); // Set flag before showing confetti
  };

  const handleSingleBillSettlement = () => {
    if (
      !currentBill.description ||
      !currentBill.amount ||
      !currentBill.paidBy
    ) {
      alert("Please fill in all bill details before proceeding.");
      return false;
    }

    // Clear existing bills
    const singleBill = {
      ...currentBill,
      amount: parseFloat(currentBill.amount),
      participants: participants.map((p) => ({
        userId: p.userId,
        shares: participantShares[p.userId]?.shares || 1,
        percentage:
          participantShares[p.userId]?.percentage || 100 / participants.length,
      })),
    };

    const processedBill = processParticipantShares(singleBill);
    if (!processedBill) return false;

    // Update bills state and calculate settlements
    setBills([processedBill]);

    // Calculate balances right away
    const balances = {};
    participants.forEach((p) => (balances[p.userId] = 0));

    // Add the amount paid to the payer's balance
    balances[processedBill.paidBy] = parseFloat(processedBill.amount);

    // Subtract each person's share
    Object.entries(processedBill.shares).forEach(([userId, share]) => {
      balances[userId] = (balances[userId] || 0) - parseFloat(share);
    });

    // Generate settlements immediately
    const newSettlements = optimizeSettlements(balances);
    setSettlements(newSettlements);
    setShouldShowConfetti(true); // Set flag when settlements are calculated

    return true;
  };

  const [shouldShowConfetti, setShouldShowConfetti] = useState(false);

  const getSingleBillSteps = () => [
    "Add Participants",
    "Bill Details",
    "Settlements",
  ];
  const getGroupSteps = () => ["Add Participants", "Add Bills", "Settlements"];

  const steps = mode === "single" ? getSingleBillSteps() : getGroupSteps();

  const renderModeSelector = () => (
    <Container maxWidth="md">
      <Box sx={{ pt: 8, pb: 4 }}>
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          sx={{ mb: 6 }}
        >
          Choose your splitting mode
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <ModeSelectorCard onClick={() => setMode("single")}>
              <CardContent sx={{ textAlign: "center", p: 4 }}>
                <ReceiptIcon
                  sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  Single Bill
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Split one bill among multiple people
                </Typography>
              </CardContent>
            </ModeSelectorCard>
          </Grid>

          <Grid item xs={12} md={6}>
            <ModeSelectorCard onClick={() => setMode("group")}>
              <CardContent sx={{ textAlign: "center", p: 4 }}>
                <GroupsIcon
                  sx={{ fontSize: 48, color: "secondary.main", mb: 2 }}
                />
                <Typography variant="h6" gutterBottom>
                  Group Bills
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Split multiple bills among a group
                </Typography>
              </CardContent>
            </ModeSelectorCard>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );

  const renderGroupCards = () => (
    <Grid container spacing={3}>
      {groups.map((group) => (
        <Grid item xs={12} key={group.id}>
          <GroupCard sx={{ 
            mb: 2
          }}>
            <CardContent sx={{ p: 3 }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                mb: 2 
              }}>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    color: 'primary.main', 
                    fontWeight: 600 
                  }}
                >
                  {group.name}
                </Typography>
                {group.createdBy === user?.uid && (
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => handleDeleteGroup(group.id)}
                    startIcon={<DeleteIcon />}
                  >
                    Delete Group
                  </Button>
                )}
              </Box>
  
              <Divider sx={{ mb: 2, opacity: 0.1 }} />
  
              <Typography 
                variant="subtitle2" 
                color="text.secondary" 
                sx={{ 
                  mb: 2,
                  fontWeight: 500 
                }}
              >
                Members
              </Typography>
  
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 1.5
              }}>
                {group.members.map((member) => (
                  <MemberChip
                    key={member.userId}
                    label={member.displayName || member.email}
                    onDelete={
                      group.createdBy === user?.uid && member.userId !== user.uid
                        ? () => handleRemoveParticipantFromGroup(group.id, member.userId)
                        : undefined
                    }
                    avatar={
                      <Avatar
                        alt={member.displayName || member.email}
                        src={member.photoURL || `/api/placeholder/32/32?text=${(member.displayName || member.email || '?').charAt(0).toUpperCase()}`}
                        sx={{
                          width: 24,
                          height: 24,
                          bgcolor: 'secondary.main'
                        }}
                      >
                        {(member.displayName || member.email || '?').charAt(0).toUpperCase()}
                      </Avatar>
                    }
                    sx={{ 
                      '& .MuiChip-label': { px: 2 },
                      py: 0.5
                    }}
                  />
                ))}
  
                {group.createdBy === user?.uid && (
                  <Box sx={{ mt: 1, width: '100%' }}>
                    <UserSearch
                      value={null}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleAddParticipantToGroup(group.id, newValue);
                        }
                      }}
                      excludeUsers={group.members}
                      placeholder="Add member..."
                      multiple={false}
                      renderInput={(params) => (
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<PersonAddIcon />}
                          onClick={params.onClick}
                          sx={{
                            borderStyle: 'dashed',
                            color: 'text.secondary',
                            mt: 1
                          }}
                        >
                          Add Member
                        </Button>
                      )}
                    />
                  </Box>
                )}
              </Box>
            </CardContent>
          </GroupCard>
        </Grid>
      ))}
    </Grid>
  );

  const renderGroupBillsStep = () => (
    <Box>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Select Group
        </Typography>
        <FormControl fullWidth>
          <InputLabel>Group</InputLabel>
          <Select
            value={selectedGroup}
            onChange={(e) => {
              const newSelectedGroup = e.target.value;
              setSelectedGroup(newSelectedGroup);

              // Initialize shares for group participants
              if (newSelectedGroup) {
                const groupParticipants =
                  groups.find((g) => g.id === newSelectedGroup)?.participants ||
                  [];
                const newShares = {};
                groupParticipants.forEach((p) => {
                  newShares[p.userId] = {
                    // Change from p to p.userId
                    shares: 1,
                    percentage: 100 / groupParticipants.length,
                  };
                });
                setParticipantShares(newShares);
              }

              // Reset current bill
              setCurrentBill({
                description: "",
                amount: "",
                paidBy: "",
                splitType: "equal",
                participants: [],
              });
            }}
            label="Group"
            sx={{
              borderRadius: 2,
              backgroundColor: alpha(theme.palette.background.paper, 0.4),
            }}
          >
            <MenuItem value="">
              <em>Select a group</em>
            </MenuItem>
            {groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography variant="h6" gutterBottom color="primary">
        Add Bill
      </Typography>
      <StyledPaper sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              value={currentBill.description}
              onChange={(e) =>
                setCurrentBill({
                  ...currentBill,
                  description: e.target.value,
                })
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  backgroundColor: alpha(theme.palette.background.paper, 0.4),
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              label="Amount"
              value={currentBill.amount}
              onChange={(e) =>
                setCurrentBill({
                  ...currentBill,
                  amount: e.target.value,
                })
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  backgroundColor: alpha(theme.palette.background.paper, 0.4),
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Paid By</InputLabel>
              <Select
                value={currentBill.paidBy}
                onChange={(e) =>
                  setCurrentBill({
                    ...currentBill,
                    paidBy: e.target.value, // userId
                  })
                }
                label="Paid By"
              >
                <MenuItem value="">
                  <em>Select person</em>
                </MenuItem>
                {groups
                  .find((g) => g.id === selectedGroup)
                  ?.participants.map((p) => (
                    <MenuItem key={p.userId} value={p.userId}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Avatar
                          src={p.photoURL || "/api/placeholder/40/40"}
                          sx={{ width: 24, height: 24 }}
                        />
                        <Typography>{p.displayName || p.email}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Split Type</InputLabel>
              <Select
                value={currentBill.splitType}
                onChange={(e) =>
                  setCurrentBill({
                    ...currentBill,
                    splitType: e.target.value,
                  })
                }
                label="Split Type"
                sx={{
                  borderRadius: 2,
                  backgroundColor: alpha(theme.palette.background.paper, 0.4),
                }}
              >
                <MenuItem value="equal">Equal</MenuItem>
                <MenuItem value="shares">By Shares</MenuItem>
                <MenuItem value="percentage">By Percentage</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {currentBill.splitType !== "equal" && selectedGroup && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom color="primary">
                {currentBill.splitType === "shares"
                  ? "Share Distribution"
                  : "Percentage Distribution"}
              </Typography>
              <Grid container spacing={2}>
                {groups
                  .find((g) => g.id === selectedGroup)
                  ?.participants.map((p) => (
                    <Grid item xs={12} key={p.userId}>
                      <TextField
                        fullWidth
                        label={p.displayName || p.email}
                        type="number"
                        value={
                          currentBill.splitType === "shares"
                            ? participantShares[p.userId]?.shares
                            : participantShares[p.userId]?.percentage
                        }
                        onChange={(e) =>
                          updateParticipantShare(
                            p.userId,
                            currentBill.splitType === "shares"
                              ? "shares"
                              : "percentage",
                            e.target.value
                          )
                        }
                        InputProps={{
                          endAdornment:
                            currentBill.splitType === "percentage" ? "%" : null,
                          startAdornment: (
                            <Avatar
                              src={p.photoURL || "/api/placeholder/40/40"}
                              sx={{ width: 24, height: 24, mr: 1 }}
                            />
                          ),
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <AnimatedButton
              variant="contained"
              fullWidth
              onClick={addBill}
              startIcon={<AddIcon />}
              color="secondary"
              sx={{ py: 1.5 }}
            >
              Add Bill
            </AnimatedButton>
          </Grid>
        </Grid>
      </StyledPaper>

      {/* Bills List */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom color="primary">
          Bills
        </Typography>
        <Grid container spacing={2}>
          {bills
            .filter((bill) => !selectedGroup || bill.groupId === selectedGroup)
            .map((bill, index) => (
              <Grid item xs={12} key={index}>
                <StyledPaper
                  sx={{
                    p: 3,
                    background: `linear-gradient(135deg, ${alpha(
                      theme.palette.secondary.main,
                      0.05
                    )}, ${alpha(theme.palette.primary.main, 0.05)})`,
                  }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12} sm>
                      <Typography
                        variant="h6"
                        sx={{ color: theme.palette.primary.main, mb: 1 }}
                      >
                        {bill.description}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: theme.palette.text.secondary,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <MoneyIcon sx={{ fontSize: 20 }} />
                        {bill.amount}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.secondary, mt: 0.5 }}
                      >
                        Paid by:{" "}
                        {(() => {
                          const paidByUser =
                            participants.find(
                              (p) => p.userId === bill.paidBy
                            ) ||
                            groups
                              .find((g) => g.id === bill.groupId)
                              ?.participants.find(
                                (p) => p.userId === bill.paidBy
                              );
                          return (
                            <StyledChip
                              label={
                                paidByUser?.displayName || paidByUser?.email
                              }
                              avatar={
                                <Avatar
                                  src={
                                    paidByUser?.photoURL ||
                                    "/api/placeholder/40/40"
                                  }
                                  sx={{ width: 20, height: 20 }}
                                />
                              }
                              size="small"
                            />
                          );
                        })()}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <StyledChip
                        label={`Split: ${bill.splitType}`}
                        sx={{
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.1
                          ),
                          borderColor: alpha(theme.palette.primary.main, 0.3),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      gutterBottom
                    >
                      Share Distribution
                    </Typography>
                    <Grid container spacing={1}>
                      {Object.entries(bill.shares).map(([userId, share], i) => {
                        const participant =
                          participants.find((p) => p.userId === userId) ||
                          groups
                            .find((g) => g.id === bill.groupId)
                            ?.participants.find((p) => p.userId === userId);
                        return (
                          <Grid item xs={6} sm={4} key={i}>
                            <StyledPaper sx={{ p: 1.5, textAlign: "center" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  mb: 1,
                                }}
                              >
                                <Avatar
                                  src={
                                    participant?.photoURL ||
                                    "/api/placeholder/40/40"
                                  }
                                  sx={{ width: 24, height: 24, mr: 1 }}
                                />
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {participant?.displayName ||
                                    participant?.email}
                                </Typography>
                              </Box>
                              <Typography variant="subtitle2" color="primary">
                                ${share.toFixed(2)}
                              </Typography>
                            </StyledPaper>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </StyledPaper>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );

  const renderGroupManagementStep = () => (
    <Box>
      <Box
        sx={{
          mb: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" color="primary">
          {loadingGroups ? "Loading Groups..." : "Manage Groups"}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => setShowGroupForm(true)}
        >
          Create New Group
        </Button>
      </Box>
      {fetchError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {fetchError}
        </Alert>
      )}
      {loadingGroups ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : groups.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: "center", bgcolor: "background.paper" }}>
          <Typography color="text.secondary">
            No groups found. Create your first group!
          </Typography>
        </Paper>
      ) : (
        renderGroupCards()
      )}
      {showGroupForm && (
        <GroupCard sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="subtitle1" color="primary" gutterBottom>
              Create New Group
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Group Name"
                  value={currentGroup.name}
                  onChange={(e) =>
                    setCurrentGroup({ ...currentGroup, name: e.target.value })
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                      backgroundColor: alpha(
                        theme.palette.background.paper,
                        0.4
                      ),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <UserSearch
                  multiple
                  value={currentGroup.participants}
                  onChange={(event, newValue) => {
                    setCurrentGroup({
                      ...currentGroup,
                      participants: newValue,
                    });
                  }}
                  excludeUsers={currentGroup.participants}
                  placeholder="Search users to add to group..."
                />
              </Grid>
              {currentGroup.participants.length > 0 && (
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Selected Participants
                  </Typography>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {currentGroup.participants.map((participant) => (
                      <MemberChip
                        key={participant.userId}
                        label={participant.displayName || participant.email}
                        onDelete={() => {
                          setCurrentGroup({
                            ...currentGroup,
                            participants: currentGroup.participants.filter(
                              (p) => p.userId !== participant.userId
                            ),
                          });
                        }}
                        avatar={
                          <Avatar
                            alt={participant.displayName || participant.email}
                            src={
                              participant.photoURL ||
                              `/api/placeholder/32/32?text=${(
                                participant.displayName ||
                                participant.email ||
                                "?"
                              )
                                .charAt(0)
                                .toUpperCase()}`
                            }
                            sx={{
                              width: 24,
                              height: 24,
                              bgcolor: "secondary.main",
                            }}
                          >
                            {(
                              participant.displayName ||
                              participant.email ||
                              "?"
                            )
                              .charAt(0)
                              .toUpperCase()}
                          </Avatar>
                        }
                      />
                    ))}
                  </Box>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowGroupForm(false);
                    setCurrentGroup({ id: null, name: "", participants: [] });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleAddGroup}
                >
                  Create Group
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </GroupCard>
      )}
    </Box>
  );

  const renderSettlementsStep = () => (
    <Box>
      <Typography variant="h6" gutterBottom color="primary">
        Settlements
      </Typography>
      <Grid container spacing={2}>
        {settlements.length > 0 ? (
          <Box mt={2} width="100%">
            <Grid container spacing={2}>
              {settlements.map((settlement, index) => {
                const fromUser =
                  participants.find((p) => p.userId === settlement.from) ||
                  groups
                    .find((g) => g.id === selectedGroup)
                    ?.participants.find((p) => p.userId === settlement.from);
                const toUser =
                  participants.find((p) => p.userId === settlement.to) ||
                  groups
                    .find((g) => g.id === selectedGroup)
                    ?.participants.find((p) => p.userId === settlement.to);

                return (
                  <Grid item xs={12} key={index}>
                    <StyledPaper sx={{ p: 2 }}>
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={fromUser?.photoURL || "/api/placeholder/40/40"}
                            sx={{ width: 24, height: 24, mr: 1 }}
                          />
                          <span>
                            {fromUser?.displayName || fromUser?.email}
                          </span>
                        </Box>
                        <RightIcon />
                        <Box sx={{ color: "primary.main" }}>
                          <MoneyIcon sx={{ mr: 0.5 }} />$
                          {settlement.amount.toFixed(2)}
                        </Box>
                        <RightIcon />
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={toUser?.photoURL || "/api/placeholder/40/40"}
                            sx={{ width: 24, height: 24, mr: 1 }}
                          />
                          <span>{toUser?.displayName || toUser?.email}</span>
                        </Box>
                      </Typography>
                    </StyledPaper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ) : (
          <Grid item xs={12}>
            <StyledPaper sx={{ p: 3, textAlign: "center" }}>
              <Typography color="textSecondary">
                No settlements to display. All debts are settled!
              </Typography>
            </StyledPaper>
          </Grid>
        )}
      </Grid>
    </Box>
  );

  const renderParticipantsStep = () => (
    <Box>
      <Typography variant="h6" gutterBottom color="primary">
        Add Participants
      </Typography>
      <Box sx={{ mb: 3 }}>
        <UserSearch
          multiple
          value={participants}
          onChange={(event, newValue) => {
            setParticipants(newValue);
            const newShares = {};
            newValue.forEach((participant) => {
              newShares[participant.userId] = {
                shares: 1,
                percentage: 100 / newValue.length,
              };
            });
            setParticipantShares(newShares);
          }}
          excludeUsers={participants}
          placeholder="Search users to add..."
        />
      </Box>

      {participants.length > 0 && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1" gutterBottom color="textSecondary">
            Selected Participants
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {participants.map((participant) => (
              <StyledChip
                key={participant.userId}
                label={participant.displayName || participant.email}
                onDelete={() => {
                  setParticipants(
                    participants.filter((p) => p.userId !== participant.userId)
                  );
                  // Remove from shares
                  const newShares = { ...participantShares };
                  delete newShares[participant.userId];
                  setParticipantShares(newShares);
                }}
                avatar={
                  <Avatar
                    src={participant.photoURL || "/api/placeholder/40/40"}
                  />
                }
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );

  const handleNext = () => {
    if (mode === "single" && activeStep === 0 && participants.length === 0) {
      alert("Please add at least one participant before proceeding.");
      return;
    }

    if (mode === "group") {
      if (activeStep === 0 && groups.length === 0) {
        alert(
          "Please create at least one group with participants before proceeding."
        );
        return;
      }
      if (activeStep === 1) {
        if (bills.length === 0) {
          alert("Please add at least one bill before proceeding.");
          return;
        }
        if (!selectedGroup) {
          alert("Please select a group before proceeding.");
          return;
        }
        // Calculate settlements before changing step
        handleCalculateSettlements();
        setActiveStep((prevStep) => prevStep + 1);
        return;
      }
    }

    let canProceed = true;

    if (mode === "single" && activeStep === 1) {
      canProceed = handleSingleBillSettlement();
    }

    if (canProceed) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const renderStepContent = (step) => {
    if (mode === "single") {
      switch (step) {
        case 0:
          return renderParticipantsStep();
        case 1:
          return renderSingleBillStep();
        case 2:
          return renderSettlementsStep();
        default:
          return null;
      }
    } else {
      switch (step) {
        case 0:
          return renderGroupManagementStep();
        case 1:
          return renderGroupBillsStep();
        case 2:
          return renderSettlementsStep();
        default:
          return null;
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderSingleBillStep = () => (
    <Box>
      <Typography variant="h6" gutterBottom color="primary">
        Bill Details
      </Typography>
      <StyledPaper sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              value={currentBill.description}
              onChange={(e) =>
                setCurrentBill({
                  ...currentBill,
                  description: e.target.value,
                })
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  backgroundColor: alpha(theme.palette.background.paper, 0.4),
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              label="Amount"
              value={currentBill.amount}
              onChange={(e) =>
                setCurrentBill({
                  ...currentBill,
                  amount: e.target.value,
                })
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  backgroundColor: alpha(theme.palette.background.paper, 0.4),
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Paid By</InputLabel>
              <Select
                value={currentBill.paidBy}
                onChange={(e) =>
                  setCurrentBill({
                    ...currentBill,
                    paidBy: e.target.value,
                  })
                }
                label="Paid By"
              >
                <MenuItem value="">
                  <em>Select person</em>
                </MenuItem>
                {participants.map((p) => (
                  <MenuItem key={p.userId} value={p.userId}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Avatar
                        src={p.photoURL || "/api/placeholder/40/40"}
                        sx={{ width: 24, height: 24 }}
                      />
                      <Typography>{p.displayName || p.email}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Split Type</InputLabel>
              <Select
                value={currentBill.splitType}
                onChange={(e) =>
                  setCurrentBill({
                    ...currentBill,
                    splitType: e.target.value,
                  })
                }
                label="Split Type"
                sx={{
                  borderRadius: 2,
                  backgroundColor: alpha(theme.palette.background.paper, 0.4),
                }}
              >
                <MenuItem value="equal">Equal</MenuItem>
                <MenuItem value="shares">By Shares</MenuItem>
                <MenuItem value="percentage">By Percentage</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {currentBill.splitType !== "equal" && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom color="primary">
                {currentBill.splitType === "shares"
                  ? "Share Distribution"
                  : "Percentage Distribution"}
              </Typography>
              <Grid container spacing={2}>
                {participants.map((p) => (
                  <Grid item xs={12} key={p.userId}>
                    <TextField
                      fullWidth
                      label={p.displayName || p.email}
                      type="number"
                      value={
                        currentBill.splitType === "shares"
                          ? participantShares[p.userId]?.shares
                          : participantShares[p.userId]?.percentage
                      }
                      onChange={(e) =>
                        updateParticipantShare(
                          p.userId,
                          currentBill.splitType === "shares"
                            ? "shares"
                            : "percentage",
                          e.target.value
                        )
                      }
                      InputProps={{
                        endAdornment:
                          currentBill.splitType === "percentage" ? "%" : null,
                        startAdornment: (
                          <Avatar
                            src={p.photoURL || "/api/placeholder/40/40"}
                            sx={{ width: 24, height: 24, mr: 1 }}
                          />
                        ),
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </StyledPaper>

      {/* Preview Section */}
      {currentBill.description && currentBill.amount && currentBill.paidBy && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Bill Preview
          </Typography>
          <StyledPaper
            sx={{
              p: 3,
              background: `linear-gradient(135deg, ${alpha(
                theme.palette.secondary.main,
                0.05
              )}, ${alpha(theme.palette.primary.main, 0.05)})`,
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm>
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.primary.main, mb: 1 }}
                >
                  {currentBill.description}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.text.secondary,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <MoneyIcon sx={{ fontSize: 20 }} />
                  {currentBill.amount}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.secondary, mt: 0.5 }}
                >
                  Paid by:{" "}
                  {(() => {
                    const paidByUser =
                      participants.find(
                        (p) => p.userId === currentBill.paidBy
                      ) ||
                      groups
                        .find((g) => g.id === currentBill.groupId)
                        ?.participants.find(
                          (p) => p.userId === currentBill.paidBy
                        );
                    return (
                      <StyledChip
                        label={paidByUser?.displayName || paidByUser?.email}
                        avatar={
                          <Avatar
                            src={
                              paidByUser?.photoURL || "/api/placeholder/40/40"
                            }
                            sx={{ width: 20, height: 20 }}
                          />
                        }
                        size="small"
                      />
                    );
                  })()}
                </Typography>
              </Grid>
              <Grid item xs={12} sm="auto">
                <StyledChip
                  label={`Split: ${currentBill.splitType}`}
                  sx={{
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    borderColor: alpha(theme.palette.primary.main, 0.3),
                  }}
                />
              </Grid>
            </Grid>
          </StyledPaper>
        </Box>
      )}
    </Box>
  );

  useEffect(() => {
    if (mode === "group" && activeStep === 2) {
      handleCalculateSettlements();
    }
  }, [activeStep, mode]);

  useEffect(() => {
    if (settlements.length > 0 && activeStep === 2) {
      setShouldShowConfetti(true);
      showConfetti();
    }
  }, [settlements, activeStep]);

  useEffect(() => {
    return () => {
      setShouldShowConfetti(false);
    };
  }, []);

  useEffect(() => {
    setShouldShowConfetti(false);
  }, [mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HeaderNav />
      <Box
        sx={{
          minHeight: "100vh",
          background: `linear-gradient(135deg, ${alpha(
            "#181A1F",
            0.95
          )} 0%, ${alpha("#181A1F", 0.98)} 100%)`,
          pt: { xs: 8, md: 9 },
          pb: { xs: 2, md: 4 },
        }}
      >
        {!mode ? (
          renderModeSelector()
        ) : (
          <Container maxWidth="md">
            <StyledCard>
              <CardContent sx={{ p: { xs: 2, md: 4 } }}>
                <GradientTypography variant="h4" align="center">
                  {mode === "single"
                    ? "Single Bill Split"
                    : "Group Bills Split"}
                </GradientTypography>

                <StepperContent activeStep={activeStep} steps={steps} />

                <StepContent>{renderStepContent(activeStep)}</StepContent>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    startIcon={<BackIcon />}
                    sx={{ visibility: activeStep === 0 ? "hidden" : "visible" }}
                  >
                    Back
                  </Button>
                  {activeStep < steps.length - 1 && (
                    <AnimatedButton
                      variant="contained"
                      onClick={handleNext}
                      endIcon={<NextIcon />}
                      color="primary"
                    >
                      {(mode === "group" && activeStep === 1) ||
                      (mode === "single" && activeStep === 1)
                        ? "Calculate Settlements"
                        : "Next"}
                    </AnimatedButton>
                  )}
                </Box>
              </CardContent>
            </StyledCard>
          </Container>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default BillSplitter;
