import React from "react";
import { Box, Typography, useTheme, alpha } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";

const SettingsPage = () => {
  const { user } = useAuth();
  const theme = useTheme();
  return (
    <Box>
      <Box
        sx={{
          p: 4,
          borderRadius: 2,
          bgcolor: "background.paper",
          backgroundImage:
            "linear-gradient(145deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%)",
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{ color: "primary.main", fontWeight: "bold", mb: 4 }}
        >
          Settings
        </Typography>

        <Box
          sx={{
            mb: 4,
            pb: 4,
            borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
          }}
        >
          <Typography variant="h6" sx={{ color: "text.primary", mb: 3 }}>
            Profile
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <Box
              component="img"
              src={user?.photoURL || "/api/placeholder/96/96"}
              alt="Profile"
              sx={{
                width: 96,
                height: 96,
                borderRadius: "50%",
                border: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
              }}
            />

            <Box>
              <Typography
                variant="h5"
                sx={{ color: "text.primary", fontWeight: "medium" }}
              >
                {user?.displayName || "User Name"}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "text.secondary", mb: 1 }}
              >
                {user?.email || "email@example.com"}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.disabled" }}>
                Member since{" "}
                {user?.metadata?.creationTime
                  ? new Date(user.metadata.creationTime).toLocaleDateString()
                  : "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography variant="h6" sx={{ color: "text.primary", mb: 2 }}>
            Preferences
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            Additional settings coming soon.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsPage;
